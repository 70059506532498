import React, { useEffect, useState } from "react";
import Navbar from "../navigation/navbar";
import Footer from "../navigation/footer";
import Contact from "../navigation/footercontact";
import fondo from "../../assets/images/fondo.png";
import N1 from "../../assets/images/N1.png";
import N2 from "../../assets/images/N2.png";
import N3 from "../../assets/images/N3.png";
import N4 from "../../assets/images/N4.png";
import N5 from "../../assets/images/N5.png";
import N6 from "../../assets/images/N6.png";
import { motion } from "framer-motion";

const Nosotros = () => {

  useEffect(() => {
    // Esto hará que el scroll vuelva al inicio al montar el componente
    window.scrollTo(0, 0);
}, []); // Dependencia vacía para que se ejecute solo una vez cuando el componente se monta

const pageTransition = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 100 },
  transition: { duration: 1 }
};

const [activeTab, setActiveTab] = useState('about');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  return (
    <motion.div
    initial="initial"
    animate="animate"
    exit="exit"
    variants={pageTransition}
    >
      {/* navbar  */}
      <div className="container mx-auto">
        <Navbar />
      </div>

      {/* Hero */}
      <div className="relative w-full">
        <img
          className="absolute object-cover w-full h-64"
          src={fondo}
          alt="Fondo Landing Page"
        />
        <div className="container relative flex flex-col justify-center h-64 px-8 pt-20 mx-auto xl:px-24">
          <h1 className="text-4xl font-bold text-white">Nosotros</h1>

          {/* BreadCrumb */}
          <nav class="flex pt-2" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li class="inline-flex items-center">
                <a
                  href="/"
                  class="inline-flex items-center text-sm font-normal text-white hover:text-blue-600 "
                >
                  <svg
                    class="w-3 h-3 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  Inicio
                </a>
              </li>
              <li>
                <div class="flex items-center">
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <a
                    href="/nosotros"
                    class="ms-1 text-sm text-white font-semibold hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                  >
                    Nosotros
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Quienes somos */}
      <div className="flex items-center justify-center w-full bg-white lg:px-24 p-14">
        <div className="container flex flex-col items-center justify-center mx-auto lg:flex-row">
          {/* Texto  */}
          <div className="lg:w-1/2">
            <h2 className="py-4 text-2xl font-bold text-darkblue md:text-2xl lg:text-3xl">
              ¿Quiénes somos?
            </h2>
            <p className="text-lg leading-7 text-justify md:leading-8 lg:leading-9 md:text-lg lg:text-xl">
              Servicios Profesionales de Aviación SEPROA S.A. es una empresa
              especializada en brindar soluciones dentro del sector aeronáutico.
              Fundada en el año 2000, ofrece servicios de certificación,
              capacitación y consultoría en aviación, tanto a nivel nacional
              como internacional. Facilitamos la gestión de trámites ante
              autoridades y brindamos entrenamientos técnicos para elevar los
              estándares de seguridad, eficiencia y calidad.
            </p>
          </div>
          <div className="flex items-center justify-center w-full py-12 lg:py-8 lg:w-1/2">
            <img src={N1} alt="Imagen de referencia" className=" lg:w-2/3" />
          </div>
        </div>
      </div>

      {/* Historia */}
      <div className="flex items-center justify-center w-full bg-gray-200 lg:px-24 p-14">
        <div className="container flex flex-col-reverse items-center justify-center mx-auto lg:flex-row">
          <div className="flex items-center justify-center w-full py-12 lg:py-8 lg:w-1/2">
            <img src={N2} alt="Imagen de referencia" className=" lg:w-2/3" />
          </div>

          {/* Texto  */}
          <div className="lg:w-1/2">
            <h2 className="py-4 text-2xl font-bold text-darkblue md:text-2xl lg:text-3xl">
              Historia de SEPROA
            </h2>
            <p className="text-lg leading-7 text-justify md:leading-8 lg:leading-9 md:text-lg lg:text-xl">
              A raíz de los cambios en la regulación local relacionados con los
              decretos y reglamentos aeronáuticos costarricenses (RAC), nuestra
              empresa, desde su fundación en el año 2000, se ha enfocado en el
              desarrollo de la aviación nacional e internacional. Con
              especialización en áreas técnicas de la aviación, trabajamos para
              optimizar los procesos de certificación de compañías aéreas y
              simplificar trámites ante autoridades de aviación.
            </p>
          </div>
        </div>
      </div>

      {/* Contribuciones */}
      <div className="flex items-center justify-center w-full bg-white lg:px-24 p-14">
        <div className="container flex flex-col items-center justify-center mx-auto lg:flex-row">
          {/* Texto  */}
          <div className="lg:w-1/2">
            <h2 className="py-4 text-2xl font-bold text-darkblue md:text-2xl lg:text-3xl">
              Contribuciones
            </h2>
            <p className="text-lg leading-7 text-justify md:leading-8 lg:leading-9 md:text-lg lg:text-xl">
              El Sr. Ricardo Hibbert Fernández participó activamente en la
              revisión de la Regulación Aeronáutica Costarricense (RAC) a
              finales de los años 90, a solicitud del sector empresarial y
              privado del país, contribuyendo significativamente a la normativa
              aeronáutica.
            </p>
          </div>
          <div className="flex items-center justify-center w-full py-12 lg:py-8 lg:w-1/2">
            <img src={N3} alt="Imagen de referencia" className=" lg:w-2/3" />
          </div>
        </div>
      </div>

      {/* A quienes servimos  */}
      <div className="flex flex-col items-center justify-center bg-darkgray p-14 lg:px-0">
        {/* Text inicio  */}
        <div className="flex flex-col items-center justify-center max-w-3xl">
          <h2 className="py-4 text-2xl font-bold text-white md:text-2xl lg:text-3xl">
            ¿A quiénes servimos?
          </h2>
          <p className="text-lg leading-7 text-justify text-white md:text-center md:leading-8 lg:leading-9 md:text-lg lg:text-xl">
            Nuestra experiencia abarca diversos sectores de la aviación,
            adaptándonos a las necesidades específicas de cada cliente.
            Trabajamos de manera integral con cada uno de ellos, ofreciendo
            servicios diseñados para facilitar la gestión de trámites ante
            autoridades aeronáuticas nacionales e internacionales.
          </p>
        </div>

        {/* Tipos de aviación */}
          <div className="container flex flex-col items-center justify-center py-8 mx-auto lg:px-24 lg:p-14 lg:flex-row">
            {/* Texto  */}
            <div className="w-full lg:w-1/2">
              <h3 className="py-4 text-2xl font-bold text-white md:text-2xl lg:text-3xl">
                Tipos de aviación
              </h3>

              <h4 class="mb-2 text-lg lg:text-xl font-semibold text-white">
              Aviación General (menos de 5700 kg) - Ala fija y Rotativa:
              </h4>
              <ul class="max-w-md space-y-1 mx-8 font-normal lg:text-xl text-white list-disc list-inside dark:text-gray-400">
                <li>Corporativa</li>
                <li>Ejecutiva</li>
                <li>Privada</li>
                <li>Pasajeros</li>
                <li>Agrícola</li>
              </ul>
              <h4 class="mb-2 text-lg font-semibold lg:text-xl text-white pt-8 dark:text-white">
              Aviación Pesada (más de 5700 kg) - Ala fija y Rotativa:
              </h4>
              <ul class="max-w-md space-y-1 mx-8 lg:text-xl text-white list-disc list-inside">
                <li>Corporativa</li>
                <li>Ejecutiva</li>
                <li>Privada</li>
                <li>Comercial</li>
                <li>Carguera</li>
              </ul>
            </div>
            <div className="flex items-center justify-center w-full py-12 lg:py-8 lg:w-1/2">
              <img src={N4} alt="Imagen de referencia" className="" />
            </div>
          </div>

        {/* Servicios Especializados */}
          <div className="container flex flex-col-reverse items-center justify-center py-8 mx-auto lg:px-24 md:pt-0 lg:p-14 lg:flex-row">
{/* Imagen  */}
            <div className="flex items-center justify-center w-full py-12 lg:py-8 lg:w-1/2">
              <img src={N5} alt="Imagen de referencia" className="" />
            </div>
            
            {/* Texto  */}
            <div className="w-full lg:px-14 lg:w-1/2">
              <h3 className="py-4 text-2xl font-bold text-white md:text-2xl lg:text-3xl">
                Servicios Especializados
              </h3>

              <ul class="max-w-md space-y-1 lg:text-xl font-normal text-white list-disc list-inside dark:text-gray-400">
                <li>Talleres de Mantenimiento Aeronáutico (OMA MRAC 145)</li>
                <li>Escuelas de enseñanza aeronáutica (vuelo y mantenimiento).</li>
                <li>Servicios de Soporte en Tierra: Distribución de combustibles (privado/comercial), certificación de drones y ground handling (FBO).</li>
              </ul>
            </div>
            
          </div>
      </div>

      {/* Servicios brindados */}
      <div className="flex items-center justify-center w-full lg:px-24 p-14">
        <div className="container flex flex-col items-center justify-center mx-auto lg:flex-row">
          {/* Texto  */}
          <div className="w-full lg:w-1/2">
            <h2 className="py-4 text-2xl font-bold text-darkblue md:text-2xl lg:text-3xl">
            ¿Qué servicios brindamos?
            </h2>
            <h4 class="mb-2 text-lg font-semibold lg:text-xl text-darkblue">
            Certificaciones y trámites: 
              </h4>
              <ul class="max-w-md space-y-1 mx-8 lg:text-xl text-darkblue list-disc list-inside">
                <li>Documentación para COA y CO</li>
                <li>Auditorías</li>
                <li>Certificación de talleres y escuelas</li>
              </ul>
            <h4 class="mb-2 pt-8 text-lg lg:text-xl font-semibold text-darkblue">
            Soporte en Accidentes: 
              </h4>
              <ul class="max-w-md space-y-1 lg:text-xl mx-8 text-darkblue list-disc list-inside">
                <li>Animación Digital.</li>
                <li>Avalúos.</li>
                <li>Control de Calidad y SMS.</li>
              </ul>
            <h4 class="mb-2 text-lg pt-8 font-semibold lg:text-xl text-darkblue">
            Capacitación: 
              </h4>
              <ul class="max-w-md space-y-1 mx-8 lg:text-xl text-darkblue list-disc list-inside">
                <li>Cursos en aeronaves comerciales.</li>
                <li>Agrícolas y motores PT6.</li>
              </ul>
          </div>
          <div className="flex items-center justify-center w-full py-12 lg:py-8 lg:w-1/2">
            <img src={N6} alt="Imagen de referencia" className=" lg:w-2/3" />
          </div>
        </div>
      </div>

      {/* Nuestro compromiso  */}
      <div className="flex flex-col w-full bg-white p-14 lg:px-24">
        <h2 className="w-full text-2xl font-bold md:text-3xl lg:text-4xl text-darkblue">Nuestro compromiso</h2>
        {/* mvv  */}
        <div className="flex flex-col w-full py-8 lg:space-x-14 lg:flex-row">
          {/* mision vision  */}
          <div className="flex flex-col lg:w-1/2 lg:pr-4">
          {/* mision  */}
          <div>

            <h3 className="py-4 pr-4 text-lg font-bold border-b-8 w-fit border-lightblue text-darkblue md:text-xl lg:text-2xl">
              Misión
            </h3>
            <p className="py-4 text-lg text-justify text-darkblue md:text-xl">
            Brindar soluciones en aviación con altos estándares de seguridad, eficiencia y calidad, para satisfacer plenamente las necesidades de nuestros clientes y garantizar la excelencia en cada proyecto.
            </p>
          </div>
          {/* vision  */}
          <div>

            <h3 className="py-4 pr-4 text-lg font-bold border-b-8 w-fit border-lightblue text-darkblue md:text-xl lg:text-2xl">
              Visión
            </h3>
            <p className="py-4 text-lg text-justify text-darkblue md:text-xl">
            Ser líderes en la capacitación avanzada y en la provisión de servicios de excelencia en la industria de la aviación. Nos enfocamos en especializar a profesionales ya capacitados, preparándolos para fortalecer la seguridad operacional en sus entornos de trabajo.            </p>
          </div>
          </div>
          {/* valores  */}
          <div className="flex flex-col lg:w-1/2">
          <div>

            <h3 className="py-4 pr-4 text-lg font-bold border-b-8 w-fit border-lightblue text-darkblue md:text-xl lg:text-2xl">
              Valores
            </h3>
            {/* valores punto a punto */}
            <div className="flex flex-col py-4 space-y-5">

            <div>
              <h4 className="text-xl font-semibold text-darkblue">Confianza</h4>
            <p className="text-lg text-darkblue md:text-xl">
            Formación sólida para contribuir a la seguridad operacional.            </p>
            </div>
            <div>
              <h4 className="text-xl font-semibold text-darkblue">Seguridad</h4>
            <p className="text-lg text-darkblue md:text-xl">
            Altos estándares de seguridad en cada área de trabajo.            </p>
            </div>
            <div>
              <h4 className="text-xl font-semibold text-darkblue">Mejora Continua</h4>
            <p className="text-lg text-darkblue md:text-xl">
            Crecimiento y adaptación constante.            </p>
            </div>
            <div>
              <h4 className="text-xl font-semibold text-darkblue">Innovación y Adaptabilidad</h4>
            <p className="text-lg text-darkblue md:text-xl">
            Vanguardismo y evolución en tendencias aeronáuticas.            </p>
            </div>
            </div>
          </div>

          </div>
        </div>
      </div>

      {/* NDT  */}
      <div className="pt-0 bg-white p-14 lg:px-14">
      <h2 className="max-w-5xl pb-8 mx-auto text-2xl font-bold md:text-3xl lg:text-4xl text-darkblue">Además de SEPROA, ofrecemos NDT:
      </h2>

      <ul className="flex flex-wrap max-w-5xl mx-auto text-sm font-medium text-center text-white border-b border-gray-200 rounded-t-lg bg-gradient-to-r from-lightblue to-lightblue2" role="tablist">
        <li className="me-2">
          <button
            onClick={() => handleTabClick('about')}
            className={`inline-block p-4 hover:text-lightblue2 hover:bg-gray-100 ${activeTab === 'about' ? 'bg-gray-100 text-lightblue2' : ''}`}
            role="tab"
            aria-controls="about"
            aria-selected={activeTab === 'about'}
          >
            Acerca de
          </button>
        </li>
        <li className="me-2">
          <button
            onClick={() => handleTabClick('services')}
            className={`inline-block p-4 hover:text-lightblue2 hover:bg-gray-100 ${activeTab === 'services' ? 'bg-gray-100 text-lightblue2' : ''}`}
            role="tab"
            aria-controls="services"
            aria-selected={activeTab === 'services'}
          >
            Servicios
          </button>
        </li>
      </ul>

      <div id="defaultTabContent" className="max-w-5xl mx-auto">
        {/* Nosotros Tab */}
        {activeTab === 'about' && (
          <div className="p-4 rounded-lg rounded-t-none bg-gray-50 md:p-8 dark:bg-gray-800" id="about" role="tabpanel">
            <h2 className="mb-3 text-3xl font-bold tracking-tight text-darkblue dark:text-white">
              NDT Technological Solutions - Costa Rica
            </h2>
            <p className="mb-3 text-gray-500 dark:text-gray-400">
            Aparte de nuestra experiencia con SEPROA S.A., nos enorgullece presentar a NDT Technical Solutions Costa Rica S.A. fundada en 2008, una empresa pionera en el campo de la inspección no destructiva (NDT). En NDT, somos expertos en ofrecer soluciones avanzadas de inspección no destructiva (NDT) para garantizar la seguridad y el rendimiento de tus activos industriales.
            </p>
            <a href="https://ndttechscr.com" className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700">
              Conocer más
              <svg className="w-2.5 h-2.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
              </svg>
            </a>
          </div>
        )}

        {/* Services Tab */}
        {activeTab === 'services' && (
          <div className="p-4 bg-white rounded-lg md:p-8" id="services" role="tabpanel">
            <h2 className="mb-5 text-lg font-bold tracking-tight text-darkblue lg:text-2xl">
            Entre los servicios más destacados que ofrecemos se incluyen:
            </h2>
            <ul role="list" className="space-y-4 text-gray-500 dark:text-gray-400">
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Pruebas e Inspecciones Visuales</span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Inspecciones Boroscópicas</span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Rayos X (Radiografía)</span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Ultrasonido (Phase Array)
                </span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Partículas Magnéticas
                </span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Eddy Current
                </span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Tintes Penetrantes
                </span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Inspección de Soldaduras
                </span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Análisis de Vibraciones y Medición de Espesores

                </span>
              </li>
              <li className="flex items-center space-x-2 rtl:space-x-reverse">
                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span className="leading-tight">Termografía y Análisis de Aceite y Combustible
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>

      <Contact />
      <Footer />

    </motion.div>
  );
};

export default Nosotros;
