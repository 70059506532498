import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import whitelogo from "../../assets/images/whitelogo.svg";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Iniciar el estado como false (el menú oculto)

  // Detectar el tamaño de la pantalla
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize); // Detectar cambio de tamaño

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    if (windowWidth < 1024) {
      setIsOpen(!isOpen); // Solo permitir toggle en pantallas pequeñas
    }
  };

  // Asegurarse de que el menú esté siempre abierto en pantallas grandes
  useEffect(() => {
    if (windowWidth >= 1024) {
      setIsOpen(true);
    }
  }, [windowWidth]);

  return (
    <nav className={`fixed top-0 left-0 z-50 w-full transition-colors duration-700 ${scrolled ? 'bg-darkgray bg-opacity-90 hover:bg-opacity-100' : 'bg-darkgray hover:bg-opacity-90 bg-opacity-40'}`}>
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-8 py-4 mx-auto">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={whitelogo} className="h-12" alt="SEPROA S.A Logo" />
        </Link>
        <div className="flex space-x-3 lg:order-2 lg:space-x-0 rtl:space-x-reverse">
          <Link to="/contacto">
            <button
              type="button"
              className="px-6 py-2 text-lg font-bold text-center text-white rounded-md hover:bg-gradient-to-r bg-gradient-to-r from-lightblue to-lightblue2 focus:ring-2 focus:ring-blue-300"
            >
              Contacto
            </button>
          </Link>
          {/* Icono de hamburguesa */}
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center justify-center w-10 h-10 p-2 my-auto text-sm text-white border-2 border-white rounded-lg lg:hidden hover:bg-gray-600 hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-gray-200 border-opacity-20"
            aria-controls="navbar-sticky"
            aria-expanded={isOpen ? "true" : "false"}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        {/* Menu principal */}
        <div
          className={`transition-all duration-300 ease-in-out transform ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"} items-center justify-between w-full lg:flex lg:w-auto lg:order-1 lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 overflow-hidden`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 mt-4 space-y-4 font-medium border rounded-lg lg:p-0 bg-gray-50 lg:space-x-8 lg:space-y-0 lg:bg-transparent rtl:space-x-reverse lg:flex-row lg:mt-0 lg:border-0">
            <li>
              <Link
                to="/"
                className="block px-3 py-2 text-lg border-l-4 rounded-tr-lg rounded-br-lg text-darkblue hover:bg-gray-100 border-darkblue lg:border-l-0 lg:bg-transparent lg:text-white lg:border-white lg:rounded-none lg:hover:bg-transparent lg:hover:border-b-2 dark:text-white dark:hover:bg-gray-700 dark:border-gray-700"
                aria-current="page"
              >
                Inicio
              </Link>
            </li>
            <li>
              <Link
                to="/nosotros"
                className="block px-3 py-2 text-lg border-l-4 rounded-tr-lg rounded-br-lg text-darkblue hover:bg-gray-100 border-darkblue lg:border-l-0 lg:bg-transparent lg:text-white lg:border-white lg:rounded-none lg:hover:bg-transparent lg:hover:border-b-2 dark:text-white dark:hover:bg-gray-700 dark:border-gray-700"
              >
                Nosotros
              </Link>
            </li>
            <li>
              <Link
                to="/servicios"
                className="block px-3 py-2 text-lg border-l-4 rounded-tr-lg rounded-br-lg text-darkblue hover:bg-gray-100 border-darkblue lg:border-l-0 lg:bg-transparent lg:text-white lg:border-white lg:rounded-none lg:hover:bg-transparent lg:hover:border-b-2 dark:text-white dark:hover:bg-gray-700 dark:border-gray-700"
              >
                Servicios
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
