import React, { useRef, useState } from "react";
import { MapPin, Phone, Mail, Package2 } from "lucide-react";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'

const Contact = () => {

  // Email js 
  const form = useRef();
  // Usar useRef para manipular el textarea
  const textareaRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Tamaño del message 
  const handleInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reinicia el alto para recalcular
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta el alto basado en el contenido
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Datos del formulario
    const formData = {
      user_name: form.current['user_name'].value,
      user_email: form.current['user_email'].value,
      user_company: form.current['user_company'].value,
      user_message: form.current['user_message'].value,
    };

    try {
       // Enviar correo a la empresa
       await emailjs.send(
        "service_gfmmjzn",
        "template_5msbp0r", // Plantilla para la empresa
        formData,
        "_uMYJ4TbRdZxDEsaS"
      );

      // Enviar correo al usuario
      await emailjs.send(
        "service_gfmmjzn",
        "template_e53y2za", // Plantilla para el usuario
        formData,
        "_uMYJ4TbRdZxDEsaS"
      );

      // Mostrar alerta de éxito
      Swal.fire({
        icon: "success",
        title: "¡Mensaje enviado!",
        text: "Tu mensaje ha sido enviado correctamente. Nos pondremos en contacto contigo pronto.",
        confirmButtonColor: "#3085d6",
      });

    } catch (error) {
      console.error("Error al enviar el correo:", error.text);

      // Mostrar alerta de error
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al enviar tu mensaje. Por favor, intenta nuevamente.",
        confirmButtonColor: "#d33",
      });
    } finally {
      setIsSubmitting(false);
    }

    e.target.reset(); // Opcional: Limpiar el formulario
  };

  return (
    <div className="relative">
    {/* Fondo  */}
    <div className="absolute w-full bg-white h-1/2"></div>
    <div className="absolute bottom-0 w-full h-1/2 bg-darkgray"></div>

    {/* Contenido */}
      <div className="relative z-20 w-full p-10 mx-auto space-y-8 lg:w-4/5 bg-gradient-to-r from-lightblue to-lightblue2 rounded-xl">
        {/* Texto */}
        <div className="flex flex-col space-y-4">
          <h2 className="text-2xl font-bold text-white md:text-3xl lg:text-4xl">
            Contacto
          </h2>
          <p className="text-lg text-white md:text-lg lg:text-xl">
            Tu socio confiable en capacitación, certificación y consultoría
            aeronáutica
          </p>
        </div>
        {/* Datos y forms */}
        <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row">
          {/* Información de contacto */}
          <div className="flex flex-col items-start justify-center h-full space-y-4 md:px-8 md:w-1/2">
            <div className="flex items-center justify-center space-x-4">
              <MapPin color="#FFFFFF" />
              <p className="text-white">
                Aeropuerto Internacional Tobías Bolaños Palma, Pavas, San
                José-Costa Rica.
              </p>
            </div>

            <div className="flex items-center justify-center space-x-4">
              <Phone color="#FFFFFF" size={20} />
              <p className="text-white">(506) 2291-1318.</p>
            </div>

            <div className="flex items-center justify-center space-x-4">
              <Mail color="#FFFFFF" size={18} />
              <p className="flex flex-col items-start justify-center text-white">
                <a href="mailto:info@seproacr.com">info@seproacr.com</a>
                <a href="mailto:rhibbert@seproacr.com">rhibbert@seproacr.com</a>
              </p>
            </div>

            <div className="flex items-center justify-center space-x-4">
              <Package2 color="#FFFFFF" />
              <p className="text-white">
              P.O Box: 604-1260 Plaza Colonial - Escazú, San José, Costa Rica.
              </p>
            </div>
          </div>

          {/* Formulario */}
          <form ref={form} onSubmit={sendEmail}  className="w-full max-w-md px-4 mx-auto md:w-1/2">

            {/* Nombre y Apellido */}
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="user_name"
                  id="user_name"
                  className="block py-2.5 px-0 w-full text-sm font-normal text-white bg-transparent border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-darkblue peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="user_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-200 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-darkblue  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Nombre
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="user_company"
                  id="user_company"
                  className="block py-2.5 px-0 w-full text-sm font-normal text-white bg-transparent border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-darkblue peer"
                  placeholder=" "
                />
                <label
                  htmlFor="user_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-200 font-semibold  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-darkblue  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Empresa
                </label>
              </div>
            </div>

            {/* Email */}
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="email"
                name="user_email"
                id="user_email"
                className="block py-2.5 px-0 w-full text-sm font-normal text-white bg-transparent border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-darkblue peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="user_email"
                className="peer-focus:font-medium absolute text-sm text-gray-200 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-darkblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Correo
              </label>
            </div>

            {/* Descripción */}
            <div className="relative z-0 w-full mb-5 group">
              <textarea
                name="user_message"
                id="user_message"
                rows="1"
                ref={textareaRef}
                onInput={handleInput}
                className="block py-2.5 px-0 w-full text-sm font-normal text-white bg-transparent border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-darkblue peer resize-none overflow-hidden"
                placeholder=" "
              ></textarea>
              <label
                htmlFor="user_message"
                className="peer-focus:font-medium absolute text-sm text-gray-200 font-semibold dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-darkblue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Mensaje
              </label>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-white hover:bg-transparent hover:border-2 hover:border-white focus:outline-none rounded-sm text-sm w-full text-lightblue hover:text-white font-bold px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
