import React, { useRef } from "react";
import { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import 'animate.css'

// Componentes
import Navbar from "../navigation/navbar";
import Footer from "../navigation/footer";
import Contacto from "../navigation/footercontact";
// Imagenes
import fondo from "../../assets/images/fondo.png";
import L1 from "../../assets/images/L1.png";
import LS1 from "../../assets/images/LS1.png";
import LS2 from "../../assets/images/LS2.png";
import LS3 from "../../assets/images/LS3.png";
const LandingPage = () => {
  useEffect(() => {
    // Esto hará que el scroll vuelva al inicio al montar el componente
    window.scrollTo(0, 0);
}, []); // Dependencia vacía para que se ejecute solo una vez cuando el componente se monta

  const navigate = useNavigate();

  // Crear una referencia para la siguiente sección
  const siguienteSeccionRef = useRef(null);

  // Función para desplazarse a la siguiente sección
  const scrollToNextSection = () => {
      siguienteSeccionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const goToSection = (section) => {
      navigate('/servicios', { state: { section } });
  };

  const pageTransition = {
    // initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
    transition: { duration: 1 }
  };



  return (
    <motion.div className="w-full" initial="initial"
    animate="animate"
    exit="exit"
    variants={pageTransition}>
      {/* navbar  */}
      <div className="container mx-auto">
        <Navbar />
      </div>

      {/* Hero */}
      <div className="relative md:h-screen">
        <img
          className="absolute object-cover w-full h-full"
          src={fondo}
          alt="Fondo Landing Page"
        />

        <div className="container relative h-full pt-24 mx-auto ">
          <div className="flex flex-col items-center justify-center h-full text-center lg:w-2/3 lg:text-left lg:items-start p-14">
            <h1 className="text-xl font-bold text-white md:text-3xl lg:text-5xl">
              Fortaleciendo la Seguridad Operacional en la Industria Aeronáutica
            </h1>
            <p className="my-2 text-base text-white md:text-xl lg:text-2xl lg:py-4">
              Lideres en la certificación, capacitación y consultoría
              aeronáutica, impulsando estandares de seguridad, eficiencia y
              calidad.
            </p>
            <button onClick={scrollToNextSection} className="px-4 py-2 mt-4 text-base font-bold text-white border-2 border-white rounded md:text-xl lg:text-2xl hover:bg-white hover:text-darkblue">
              Descubre más
            </button>
          </div>
        </div>
      </div>

      {/* Qué es SEPROA */}
      <div className="flex items-center justify-center w-full bg-white p-14">
        <div className="container flex flex-col items-center justify-center mx-auto lg:flex-row">
          {/* Texto  */}
          <div className="lg:w-1/2">
            <h2 className="py-4 text-2xl font-bold text-darkblue md:text-3xl lg:text-4xl">
              ¿Qué es SEPROA S.A?
            </h2>
            <p className="text-lg leading-7 text-left md:text-justify md:leading-8 lg:leading-9 md:text-xl lg:text-2xl">
              Servicios Profesionales de Aviación es una empresa que nace a raíz
              de los cambios en la regulación local relacionado con los decretos
              y reglamentos aeronáuticos costarricenses (RAC) y se convierte en
              una empresa capaz de ofrecer soluciones integrales para la
              industria aeronáutica. Facilitando la gestión de trámites ante
              autoridades aeronáuticas nacionales e internacionales, con el fin
              de elevar los estándares de seguridad, calidad y eficiencia.
            </p>
          </div>
          <div className="flex items-center justify-center w-full py-12 animate__animated animate__fadeIn lg:py-8 lg:w-1/2">
            <img src={L1} alt="Imagen de referencia" />
          </div>
        </div>
      </div>

      {/* Servicios  */}
      <div ref={siguienteSeccionRef} className="flex items-center justify-center w-full bg-darkgray p-14">
        <div className="container flex flex-col items-center justify-center mx-auto ">
          {/* textos  */}
          <div className="max-w-4xl text-center">
            <h2 className="py-4 text-2xl font-bold text-white md:text-3xl lg:text-4xl">
              Nuestros Servicios
            </h2>
            <p className="text-lg font-light text-white md:text-xl lg:text-2xl">
              Ofrecemos un conjunto integral de servicios diseñados para apoyar
              a las empresas aéreas facilitando la gestión de trámites ante
              autoridades aeronáuticas nacionales e internacionales, con el fin
              de elevar los estándares de seguridad, calidad y eficiencia.
            </p>
          </div>
          {/* Cards  */}
          <div className="flex flex-col flex-wrap items-center justify-center gap-8 py-8 space-y-8 lg:space-x-8 lg:space-y-0 lg:flex-row">
            {/* Carta 1 */}
            <div class="max-w-sm bg-white rounded-lg shadow-gray-800 hover:shadow-gray-500 flex-col shadow-lg hover:scale-105 duration-700">
              <button onClick={() => goToSection('tramites')}>
                <div class="flex items-center justify-between">
                  <img class="w-full h-full rounded-t-lg" src={LS1} alt="" />
                </div>
                <div class="p-5">
                  <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Trámites
                  </h3>
                  <p class="mb-3 font-normal text-lg md:text-xl lg:text-xl text-gray-700">
                    Facilitamos procesos de certificación y elaboración de
                    documentación técnica para empresas aéreas
                  </p>
                </div>
              </button>
            </div>

            {/* Carta 2 */}
            <div class="max-w-sm bg-white rounded-lg shadow-gray-800 hover:shadow-gray-500 flex-col shadow-lg hover:scale-105 duration-700">
              <button onClick={() => goToSection('capacitaciones')}>
                <div class="flex items-center justify-between">
                  <img class="w-full h-full rounded-t-lg" src={LS2} alt="" />
                </div>
                <div class="p-5">
                  <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Capacitaciones
                  </h3>
                  <p class="mb-3 font-normal text-lg md:text-xl lg:text-xl text-gray-700">
                    Programas de capacitación y especialización integral para
                    personal técnico y administrativo ya formado en la industria
                    aeronáutica.
                  </p>
                </div>
              </button>
            </div>

            {/* Carta 3 */}
            <div class="max-w-sm bg-white rounded-lg shadow-gray-800 hover:shadow-gray-500 flex-col shadow-lg hover:scale-105 duration-700">
              <button onClick={() => goToSection('soporte')}>
                <div class="flex items-center justify-between">
                  <img class="w-full h-full rounded-t-lg" src={LS3} alt="" />
                </div>
                <div class="p-5">
                  <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Soporte
                  </h3>
                  <p class="mb-3 font-normal text-lg md:text-xl lg:text-xl text-gray-700">
                    Consultoría y asesoría especializada en áreas técnicas,
                    legales y financieras de la industria aeronáutica
                  </p>
                </div>
              </button>
            </div>
          </div>
          {/* Botón para más información */}
          <div className="flex justify-center w-full">
            <Link to="/servicios">
            <button  className="px-4 py-2 mt-4 text-base font-bold text-white duration-200 border-gray-500 rounded shadow-md bg-gradient-to-r from-lightblue to-lightblue2 hover:scale-105 md:text-xl lg:text-2xl">
              Descubre más
            </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Datos relevantes */}
      <div className="flex items-center justify-center w-full bg-white py-14">
        <div className="container flex flex-col items-center justify-center mx-auto ">
          <h2 className="py-4 text-2xl font-bold md:text-3xl lg:text-4xl">
            Datos Relevantes
          </h2>
          {/* datos  */}
          <div className="grid w-full grid-cols-1 gap-8 gap-y-12 place-items-center justify-items-center md:grid-cols-2 lg:grid-cols-2">
            {/* dato 1 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-4xl font-black text-lightblue md:text-5xl lg:text-6xl">
                +10
              </h3>
              <p className="text-lg font-bold md:text-xl lg:text-2xl">
                Años de experiencia
              </p>
              <p className="text-base font-normal md:text-lg lg:text-xl">
                en la industria aeronáutica, brindando soluciones a nivel
                nacional e internacional.
              </p>
            </div>

            {/* dato 2 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-4xl font-black text-lightblue md:text-5xl lg:text-6xl">
                1era
              </h3>
              <p className="text-lg font-bold md:text-xl lg:text-2xl">
                empresa certificadora
              </p>
              <p className="text-base font-normal md:text-lg lg:text-xl">
                de compañías aéreas en Costa Rica y fuera de nuestras fronteras,
                abarcando todas las modalidades técnicas de la industria de la
                aviación.
              </p>
            </div>

            {/* dato 3 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-4xl font-black text-lightblue md:text-5xl lg:text-6xl">
                90%
              </h3>
              <p className="text-lg font-bold md:text-xl lg:text-2xl">
                de participación en las certificaciones
              </p>
              <p className="text-base font-normal md:text-lg lg:text-xl">
                aeronáuticas en Costa Rica, con reconocimiento y colaboración en
                procesos internacionales.
              </p>
            </div>

            {/* dato 4 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-4xl font-black text-lightblue md:text-5xl lg:text-6xl">
                Única
              </h3>
              <p className="text-lg font-bold md:text-xl lg:text-2xl">
                compañía constituida en Costa Rica
              </p>
              <p className="text-base font-normal md:text-lg lg:text-xl">
                capaz de gestionar integralmente los procesos de certificación y
                trámites aeronáuticos.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Contacto */}
      <div className="">
        <Contacto />
      </div>

      {/* Footer */}
      <div className="">
        <Footer />
      </div>
    </motion.div>
  );
};

export default LandingPage;
