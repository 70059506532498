import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/pages/landing.js';
import Contacto from './components/pages/contacto.js';
import Nosotros from './components/pages/nosotros.js';
import Servicios from './components/pages/servicios.js';
import './index.css';
import { AnimatePresence } from 'framer-motion';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <React.StrictMode>
      <SpeedInsights/>
    <ChakraProvider>
    <Router>
      {/* <Navbar /> */}
      <AnimatePresence mode='wait'>

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
      </AnimatePresence>
    </Router>
    </ChakraProvider>
    </React.StrictMode>
  );
}

export default App;