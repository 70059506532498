import React, { useRef, useState } from "react";
import Navbar from "../navigation/navbar";
import Footer from "../navigation/footer";
import { MapPin, Phone, Mail, Package2 } from "lucide-react";
import { motion } from "framer-motion";
import Swal from 'sweetalert2'
import emailjs from "@emailjs/browser";

import fondo from "../../assets/images/fondo.png";

const Contacto = () => {

  // Email js 
  const form = useRef();
  // Usar useRef para manipular el textarea
  const [isSubmitting, setIsSubmitting] = useState(false);

    
  const sendEmail1 = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Datos del formulario
    const formData = {
      user_name: form.current['user_name'].value,
      user_email: form.current['user_email'].value,
      user_company: form.current['user_company'].value,
      user_message: form.current['user_message'].value,
    };

    try {
       // Enviar correo a la empresa
       await emailjs.send(
        "service_gfmmjzn",
        "template_5msbp0r", // Plantilla para la empresa
        formData,
        "_uMYJ4TbRdZxDEsaS"
      );

      // Enviar correo al usuario
      await emailjs.send(
        "service_gfmmjzn",
        "template_e53y2za", // Plantilla para el usuario
        formData,
        "_uMYJ4TbRdZxDEsaS"
      );

      // Mostrar alerta de éxito
      Swal.fire({
        icon: "success",
        title: "¡Mensaje enviado!",
        text: "Tu mensaje ha sido enviado correctamente. Nos pondremos en contacto contigo pronto.",
        confirmButtonColor: "#3085d6",
      });

    } catch (error) {
      console.error("Error al enviar el correo:", error.text);

      // Mostrar alerta de error
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al enviar tu mensaje. Por favor, intenta nuevamente.",
        confirmButtonColor: "#d33",
      });
    } finally {
      setIsSubmitting(false);
    }

    e.target.reset(); // Opcional: Limpiar el formulario
  };

  const pageTransition = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
    transition: { duration: 1 }
  };

  return (
    <motion.div initial="initial"
    animate="animate"
    exit="exit"
    variants={pageTransition} 
    className="flex flex-col w-full ">
      <Navbar />

      {/* Contacto +Md+ */}
      <div className="flex-col items-center w-full h-screen md:flex md:flex-row">
        {/* Imagen */}
        <div className="relative w-full h-full md:w-1/2 ">
          {/* Capa oscura */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center max-w-lg mx-auto px-14 lg:p-0">
            {/* Informacion  */}
            <h2 className="py-8 pt-24 text-2xl font-bold text-white md:pt-8 md:text-3xl lg:text-4xl">
              Informacion del Contacto
            </h2>
            {/* Datos  */}
            <div>
              {/* Dato 1 */}
              <div className="flex flex-col items-start justify-center py-4 space-y-4 text-left">
                <h3 className="text-xl font-bold text-white">Ubicación</h3>
                <div className="flex items-center justify-center space-x-4">
                  <MapPin color="#FFFFFF" size={32} />
                  <p className="text-xl text-white">
                    Aeropuerto Internacional Tobías Bolaños Palma, Pavas, San
                    José-Costa Rica.
                  </p>
                </div>
              </div>

              {/* Dato 2 */}
              <div className="flex flex-col items-start justify-center py-4 space-y-4 text-left">
                <h3 className="text-xl font-bold text-white">Teléfono</h3>
                <div className="flex items-center justify-center space-x-4">
              <Phone color="#FFFFFF" size={20} />
              <p className="text-lg text-white">(506) 2291-1318.</p>
            </div>
              </div>

              {/* Dato 3 */}
              <div className="flex flex-col items-start justify-center py-4 space-y-4 text-left">
                <h3 className="text-xl font-bold text-white">Correo</h3>
              <div className="flex items-center justify-center space-x-4">
              <Mail color="#FFFFFF" size={18} />
              <p className="flex flex-col items-start justify-center text-lg text-white">
                <a href="mailto:info@seproacr.com">info@seproacr.com</a>
                <a href="mailto:rhibbert@seproacr.com">rhibbert@seproacr.com</a>
              </p>
            </div>
            </div>

              {/* Dato 2 */}
              <div className="flex flex-col items-start justify-center py-4 space-y-4 text-left">
                <h3 className="text-xl font-bold text-white">P.O Box</h3>
              <div className="flex items-center justify-center space-x-4">
              <Package2 color="#FFFFFF" />
              <p className="text-lg text-white">
              P.O Box: 604-1260 Plaza Colonial - Escazú, San José, Costa Rica.
              </p>
            </div>
            </div>
            </div>
          </div>
          {/* imagen  */}
          <img
            src={fondo}
            alt="Contacto"
            className="object-cover w-full h-full"
          />
        </div>

        {/* Formulario */}
        <div className="flex flex-col items-center justify-center py-8 md:h-screen md:w-1/2">
          <div className="w-full text-center">
            <h1 className="w-full py-8 text-2xl font-bold md:text-3xl lg:text-4xl text-darkblue">
              ¡Contáctanos!
            </h1>
          </div>
          <div className="w-full px-10">
            <form className="max-w-sm mx-auto" onSubmit={sendEmail1} ref={form}>
              {/* Nombre y Apellido en la misma línea */}
              <div className="grid gap-4 mb-5 lg:grid-cols-2">
                <div className="w-full">
                  <label
                    htmlFor="user_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Ingresa tu nombre"
                    required
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="user_company"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Empresa
                  </label>
                  <input
                    type="text"
                    id="user_company"
                    name="user_company"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Ingresa tu empresa"
                    required
                  />
                </div>
              </div>

              {/* Correo */}
              <div className="mb-5">
                <label
                  htmlFor="user_email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Correo
                </label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Ingresa tu correo"
                  required
                />
              </div>

              {/* Descripción */}
              <div className="mb-5">
                <label
                  htmlFor="user_message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Mensaje
                </label>
                <textarea
                  id="user_message"
                  name="user_message"
                  rows="4"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Déjanos tu mensaje..."
                  required
                ></textarea>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className=" bg-gradient-to-r from-lightblue text-white to-lightblue2  rounded-md text-sm w-full hover:scale-105 font-bold px-5 py-2.5 text-center"
              >
                {isSubmitting? "Enviando..." : "Enviar"}
              </button>
            </form>
          </div>
        </div>
        <div className=" md:hidden">
        <Footer/>
        </div>
      </div>
    
    </motion.div>
  );
};

export default Contacto;
