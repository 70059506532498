import React from "react";
import { useEffect, useRef} from 'react';
import { useLocation } from "react-router-dom";
import Navbar from "../navigation/navbar";
import Footer from "../navigation/footer";
import Contact from "../navigation/footercontact";
import fondo from "../../assets/images/fondo.png";
import { motion } from "framer-motion";

// Imagenes
import S1 from "../../assets/images/S1.png";
import S2 from "../../assets/images/S2.png";
import S3 from "../../assets/images/S3.png";
import S4 from "../../assets/images/S4.png";
import S5 from "../../assets/images/S5.png";
import S6 from "../../assets/images/S6.png";
import S7 from "../../assets/images/S7.png";
import S8 from "../../assets/images/S8.png";
import S9 from "../../assets/images/S9.png";
import S10 from "../../assets/images/S10.png";

const Servicios = () => {
  
    useEffect(() => {
      // Esto hará que el scroll vuelva al inicio al montar el componente
      window.scrollTo(0, 0);
  }, []); // Dependencia vacía para que se ejecute solo una vez cuando el componente se monta

  const pageTransition = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
    transition: { duration: 1 }
  };
  
  const location = useLocation();

    // Crea referencias para cada sección
    const tramitesRef = useRef(null);
    const capacitacionesRef = useRef(null);
    const soporteRef = useRef(null);

    useEffect(() => {
      if (location.state && location.state.section) {
          const section = location.state.section;
   
          let ref;
          if (section === 'tramites') {
              ref = tramitesRef;
          } else if (section === 'capacitaciones') {
              ref = capacitacionesRef;
          } else if (section === 'soporte') {
              ref = soporteRef;
          }
   
          if (ref && ref.current) {
              const yOffset = -100; // Offset de 24 píxeles hacia arriba
              const yPosition = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
   
              // Desplazamiento suave a la posición ajustada
              window.scrollTo({ top: yPosition, behavior: 'smooth' });
          }
      }
   }, [location]);
   

  
  return (
    <motion.div className="w-full" initial="initial"
    animate="animate"
    exit="exit"
    variants={pageTransition}>
      {/* navbar  */}
      <div className="container mx-auto">
        <Navbar />
      </div>

      {/* Hero */}
      <div className="relative w-full">
        <img
          className="absolute object-cover w-full h-64"
          src={fondo}
          alt="Fondo Landing Page"
        />
        <div className="container relative flex flex-col justify-center h-64 px-8 pt-20 mx-auto xl:px-24">
          <h1 className="text-4xl font-bold text-white">Servicios</h1>

          {/* BreadCrumb */}
          <nav class="flex pt-2" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li class="inline-flex items-center">
                <a
                  href="/"
                  class="inline-flex items-center text-sm font-normal text-white hover:text-blue-600 "
                >
                  <svg
                    class="w-3 h-3 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  Inicio
                </a>
              </li>
              <li>
                <div class="flex items-center">
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <a
                    href="/nosotros"
                    class="ms-1 text-sm text-white font-semibold hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                  >
                    Nosotros
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* a quien va dirigido  */}
      <div className="flex flex-col w-full md:flex-row ">
        {/* destinatarios */}
        <div className="flex flex-col justify-center md:w-1/2 p-14 bg-darkgray">
          <h2 className="py-4 text-xl font-bold text-white md:text-2xl lg:text-3xl">
            Destinatarios
          </h2>
          <p className="text-sm text-justify text-white md:text-base lg:text-lg">
            En SEPROA S.A., ofrecemos nuestros servicios especializados a una
            amplia gama de aeronaves que operan en distintas modalidades de
            aviación. Esta diversidad nos permite atender las necesidades de
            operaciones privadas, comerciales, corporativas y agrícolas,
            asegurando que cada tipo de aeronave reciba el soporte técnico y los
            estándares de seguridad que requiere.{" "}
          </p>
        </div>
        {/* Tipos de aviación */}
        <div className="p-4 py-8 bg-white md:w-1/2">
          <div className="flex flex-col px-8 lg:px-14">
            <h3 className="py-4 text-2xl font-bold text-darkgray md:text-2xl lg:text-3xl">
              Tipos de aviación
            </h3>
            <div className="flex flex-col space-y-4 lg:space-x-4 lg:space-y-0 lg:flex-row">
              <div>
                <h4 class="mb-2  text-base lg:text-lg font-semibold text-darkgray">
                  Aviación General (menos de 5700 kg) - Ala fija y Rotativa:
                </h4>
                <ul class="max-w-md space-y-1 mx-8 font-normal lg:text-lg text-darkgray list-disc list-inside">
                  <li>Corporativa</li>
                  <li>Ejecutiva</li>
                  <li>Privada</li>
                  <li>Pasajeros</li>
                  <li>Agrícola</li>
                </ul>
              </div>
              <div>
                <h4 class="mb-2 text-base font-semibold lg:text-lg text-darkgray">
                  Aviación Pesada (más de 5700 kg) - Ala fija y Rotativa:
                </h4>
                <ul class="max-w-md space-y-1 mx-8 lg:text-lg text-darkgray list-disc list-inside">
                  <li>Corporativa</li>
                  <li>Ejecutiva</li>
                  <li>Privada</li>
                  <li>Comercial</li>
                  <li>Carguera</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* servicios especializados */}
      <div className="pt-8 pb-14 bg-gradient-to-r from-sky-600 text to-lightblue2">
        <div className="container flex flex-col items-center justify-center mx-auto ">
          <h2 className="py-4 text-xl font-bold text-white md:text-2xl lg:text-3xl">
            Sectores especializados
          </h2>
          {/* datos  */}
          <div className="grid w-full grid-cols-1 gap-8 gap-y-8 md:gap-y-12 place-items-center justify-items-center md:grid-cols-2 lg:grid-cols-2">
            {/* dato 1 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-lg font-semibold text-left text-white md:text-xl lg:text-xl">
                Talleres de Mantenimiento Aeronáutico (OMA MRAC 145)
              </h3>
              <p className="text-sm font-normal text-justify text-white md:text-base lg:text-lg">
                Colaboramos con organizaciones de mantenimiento aeronáutico
                certificadas, brindándoles apoyo en la gestión de manuales,
                documentación técnica y normativas de calidad, para que
                mantengan la trazabilidad y seguridad en sus procedimientos de
                mantenimiento.
              </p>
            </div>

            {/* dato 2 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="w-full text-lg font-semibold text-left text-white md:text-xl lg:text-xl">
                Operadores de Servicios de Soporte en Tierra
              </h3>
              <p className="text-sm font-normal text-justify text-white md:text-base lg:text-lg">
                Apoyamos a empresas de soporte en tierra, como distribuidores de
                combustibles y servicios de ground handling (manejo en tierra),
                con certificaciones y consultoría técnica para garantizar la
                seguridad y calidad en la asistencia que brindan a las
                aeronaves.
              </p>
            </div>

            {/* dato 3 */}
            <div className="flex flex-col items-start justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-lg font-semibold text-left text-white md:text-xl lg:text-xl">
                Escuelas de Enseñanza Aeronáutica
              </h3>
              <p className="text-sm font-normal text-justify text-white md:text-base lg:text-lg">
                Asesoramos y certificamos instituciones que imparten formación
                aeronáutica, tanto en instrucción de vuelo como en
                mantenimiento. Ayudamos a estas escuelas a cumplir con las
                normativas y estándares de calidad en sus programas educativos,
                elevando la especialización de nuevos profesionales de la
                aviación.
              </p>
            </div>

            {/* dato 4 */}
            <div className="flex flex-col items-center justify-center max-w-lg px-8 space-y-2 text-center">
              <h3 className="text-lg font-semibold text-left text-white md:text-xl lg:text-xl">
                Operadores de Drones y Aeronaves No Tripuladas:
              </h3>
              <p className="text-sm font-normal text-justify text-white md:text-base lg:text-lg">
                Ofrecemos servicios de certificación y asesoría para operadores
                de drones, tanto comerciales como privados, ayudándoles a
                cumplir con la normativa vigente para aeronaves no tripuladas y
                asegurar un manejo responsable y seguro de estas operaciones.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* soluciones y servicios */}
      <div className="bg-white ">
        <h2 className="py-8 text-xl font-bold text-center pt-14 text-darkgray md:text-2xl lg:text-3xl">
          Soluciones y servicios
        </h2>
{/* tramites */}
<div>
          {/* titulo  */}
          <h3 ref={tramitesRef} className="max-w-md py-4 pr-8 text-xl font-bold text-left text-white rounded-r-full bg-gradient-to-r from-lightblue to-lightblue2 pl-14 md:text-2xl lg:text-3xl">
            1. Trámites
          </h3>

          <div className="p-8">
            {/* capacitaciones */}
            <div className="flex flex-col items-center space-y-8 md:space-y-0 md:space-x-12 md:flex-row">
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-xl font-semibold border-b-8 w-fit md:text-2xl lg:text-3xl text-darkblue border-b-lightblue">
                  Capacitaciones
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  Ofrecemos asistencia completa en los procesos de certificación
                  para empresas nacionales e internacionales, abarcando varias
                  modalidades, entre ellas:
                  <ul className="pt-4 ml-4 space-y-4 font-medium list-disc list-inside md:ml-6">
                    <li>
                      Certificado de Operador Aéreo (COA)
                      <p className="font-normal ">
                        Requisito esencial para transporte comercial de
                        pasajeros y carga.
                      </p>
                    </li>
                    <li>
                      Certificado Operativo (CO)
                      <p className="font-normal ">
                        Autorizaciones para trabajos aéreos especiales
                        (agricultura, transporte de cargas especiales, etc).
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover h-72 rounded-xl"
                  src={S1}
                  alt="Capacitaciones"
                />
              </div>
            </div>
            {/* Documentacion tecnica */}
            <div className="flex flex-col-reverse items-center space-y-8 md:mt-14 md:space-y-0 md:space-x-12 md:flex-row">
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover lg:px-16 pt-14 md:py-0 rounded-xl"
                  src={S2}
                  alt="Capacitaciones"
                />
              </div>
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-xl font-semibold border-b-8 w-fit md:text-2xl lg:text-3xl text-darkblue border-b-lightblue">
                  Documentación Técnica
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  Elaboramos y gestionamos una amplia gama de manuales y
                  programas esenciales para la operación segura y eficiente de
                  aeronaves, como:
                  {/* ul 1 */}
                  <p className="mt-4 font-medium">Elaboración y control de:</p>
                  <ul className="pt-2 space-y-2 font-normal text-left list-disc list-inside md:ml-6">
                    <li>
                      Manuales de Operaciones (<strong>MO</strong>)
                    </li>
                    <li>
                      Programas de Mantenimiento (<strong>PM</strong>)
                    </li>
                    <li>
                      Manuales de Control de Mantenimiento (<strong>MCM</strong>
                      )
                    </li>
                    <li>
                      Manual de Organización de Mantenimiento (
                      <strong>MOM</strong>)
                    </li>
                    <li>
                      Lista de Equipos Mínimos (<strong>MEL</strong>)
                    </li>
                    <li>Documentación de Manuales de Despacho</li>
                    <li>
                      Manual de Seguridad Operacional (<strong>SMS</strong>)
                    </li>
                    <li>
                      Manual de Mercancías Peligrosas (<strong>DG</strong>)
                    </li>
                    <li>Programa de Prevención y Control de la Corrosión</li>
                    <li>Manual de Manejo de Control y Llamado de Aeronaves</li>
                    <li>Entre otros.</li>
                  </ul>
                  {/* ul 2 */}
                  <p className="mt-4 font-medium ">Documentos Técnicos:</p>
                  <ul className="pt-2 ml-4 space-y-2 font-normal list-disc list-inside md:ml-6">
                    <li>Manuales de Procedimientos</li>
                    <li>Guías de Servicios</li>
                  </ul>
                </p>
              </div>
            </div>
            {/* Valoracion */}
            <div className="flex flex-col items-center mt-8 space-y-8 md:mt-14 md:space-y-0 md:space-x-12 md:flex-row">
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-xl font-semibold border-b-8 w-fit md:text-2xl lg:text-3xl text-darkblue border-b-lightblue">
                  Valoración de Aeronaves
                </h4>
                <ul className="pt-4 space-y-4 text-sm font-normal text-justify list-disc list-inside text-darkblue md:text-base lg:text-lg md:ml-6">
                  <li>
                    Evaluaciones e inspecciones físicas y documentales a las
                    diferentes aeronaves para su conformidad y aceptación por
                    parte de las autoridades.
                  </li>
                  <li>
                    Análisis y preparación de récords de aeronaves y su
                    trazabilidad documental.
                  </li>
                </ul>
              </div>
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover h-72 md:mt-0 rounded-xl"
                  src={S3}
                  alt="Capacitaciones"
                />
              </div>
            </div>
            {/* Transacciones */}
            <div className="flex flex-col-reverse items-center space-y-8 md:mt-14 md:space-y-0 md:space-x-12 md:flex-row">
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover mt-8 h-80 md:mt-0 rounded-xl"
                  src={S4}
                  alt="Capacitaciones"
                />
              </div>
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-xl font-semibold border-b-8 w-fit md:text-2xl lg:text-3xl text-darkblue border-b-lightblue">
                  Transacciones
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  SEPROA S.A. ofrece un conjunto completo de servicios para
                  facilitar las transacciones de compra, venta, importación y
                  exportación de aeronaves y componentes.
                  <ul className="pt-4 ml-4 space-y-4 font-medium list-disc list-inside md:ml-6">
                    <li>
                      Venta y Compra de Aeronaves:
                      <p className="font-normal ">
                        Acompañamos a nuestros clientes en el proceso de
                        adquisición y venta de aeronaves, ayudándoles a tomar
                        decisiones informadas.
                      </p>
                    </li>
                    <li>
                      Importación y Exportación de Aeronaves, Componentes o
                      Partes:
                      <p className="font-normal ">
                        Gestionamos todos los trámites necesarios para la
                        importación y exportación de aeronaves y sus
                        componentes, abarcando desde la documentación aduanera
                        hasta el cumplimiento de normativas locales e
                        internacionales.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            {/* Avalúos y Peritajes */}
            <div className="flex flex-col items-center mt-8 space-y-8 md:mt-14 md:space-y-0 md:space-x-12 md:flex-row">
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-xl font-semibold border-b-8 w-fit md:text-2xl lg:text-3xl text-darkblue border-b-lightblue">
                  Avalúos y Peritajes
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  En SEPROA S.A ofrecemos servicios profesionales de avalúos y
                  peritajes especializados en aeronaves y sus componentes.
                  <ul className="pt-4 ml-4 space-y-4 font-medium list-disc list-inside md:ml-6">
                    <li>
                      Avalúos de Aeronaves:
                      <p className="font-normal ">
                        Realizamos valoraciones de aeronaves considerando el
                        estado estructural, la vida útil de los componentes y el
                        historial de mantenimiento.
                      </p>
                    </li>
                    <li>
                      Peritajes Técnicos:
                      <p className="font-normal ">
                        Ofrecemos peritajes aeronáuticos para casos judiciales y
                        evaluaciones para entidades gubernamentales y privadas.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover h-72 rounded-xl"
                  src={S5}
                  alt="Capacitaciones"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Capaticaciones */}
        <div>
          {/* titulo  */}
          <h3 ref={capacitacionesRef} className="max-w-md py-4 pr-8 text-xl font-bold text-left text-white rounded-r-full bg-gradient-to-r from-lightblue to-lightblue2 pl-14 md:text-2xl lg:text-3xl">
            2. Capacitaciones
          </h3>

          {/* capacitaciones */}
          <div className="p-14 space-y-14">
            {/* nuestros entrenamientos */}
            <div className="flex flex-col items-center space-y-8 md:space-y-0 md:space-x-12 md:flex-row">
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <div>
                  <h4 className="pb-2 text-xl font-semibold md:text-xl lg:text-3xl">
                    Entrenamienos y Cursos
                  </h4>
                  <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                    Ofrecemos un programa integral de entrenamientos y cursos
                    especializados en el ámbito aeronáutico para personal ya
                    capacitado, dirigidos a mejorar las competencias técnicas y
                    operativas del personal en áreas clave de la industria.
                  </p>
                </div>
                <h4 className="pb-2 pr-8 text-lg font-semibold border-b-4 w-fit md:text-lg lg:text-xl text-darkblue border-b-lightblue">
                  Nuestros entrenamientos:
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  <ul className="pt-4 space-y-4 font-medium list-inside">
                    <li>
                    Evaluación para Licencias MT2:
                      <p className="font-normal ">
                      Contamos con inspectores autorizados que evalúan la pericia de aspirantes a licencias MT2, proporcionando exámenes prácticos y orales que cumplen con los requisitos establecidos para esta certificación de mantenimiento técnico.
                      </p>
                    </li>
                    <li>
                    Entrenamiento en Aeronaves y Componentes:
                      <p className="font-normal ">
                      Ofrecemos formación técnica en diferentes tipos de aeronaves y sus componentes, clasificadas en:
                      </p>
                      <ul className="pt-4 ml-2 space-y-2 font-medium list-disc list-inside md:ml-4">
                        <li>Producto tipo Clase 1:
                            <span className="font-normal"> Aeronaves completas</span>
                        </li>
                        <li>Producto tipo Clase 2:
                            <span className="font-normal"> Motores y hélices.</span>
                        </li>
                        <li>Producto tipo Clase 3:
                            <span className="font-normal"> Partes y componentes individuales de aeronaves.</span>
                        </li>
                        </ul>
                    </li>
                    <li>
                    Capacitación para Personal Técnico y Administrativo:
                      <p className="font-normal ">
                      Nuestro programa incluye capacitaciones especializadas para el personal en áreas de operación, administración, aviación y planificación (planning). Ofrecemos formación tanto técnica como administrativa, que abarca desde gestión operativa hasta procedimientos legales y financieros para la industria aeronáutica.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover rounded-xl"
                  src={S6}
                  alt="Capacitaciones"
                />
              </div>
            </div>
            {/* temáticas */}
            <div className="flex flex-col-reverse items-center space-y-8 md:space-y-0 md:space-x-12 md:flex-row">
                {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover mt-8 md:mt-0 rounded-xl"
                  src={S7}
                  alt="Capacitaciones"
                />
              </div>
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-lg font-semibold border-b-4 w-fit md:text-lg lg:text-xl text-darkblue border-b-lightblue">
                Temáticas de Cursos Especializados:
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  <ul className="pt-4 space-y-4 font-medium list-disc list-inside">
                    <li>
                    Curso de Factores Humanos:
                      <p className="font-normal ">
                      Dirigido al personal de mantenimiento, operaciones y administrativo, enfocándose en la interacción humana en entornos de alta seguridad y las mejores prácticas para minimizar errores.
                      </p>
                    </li>
                    <li>
                    Curso de Metrología:
                      <p className="font-normal ">
                      Capacitación en la ciencia de la medición, fundamental para el control de calidad y el mantenimiento de precisión en componentes aeronáuticos.
                      </p>
                    </li>
                    <li>
                    Curso de Motores Recíprocos y Turbohélices:
                      <p className="font-normal ">
                      Formación en el funcionamiento y mantenimiento de motores recíprocos y turbohélices, orientada a técnicos especializados.
                      </p>
                    </li>
                    <li>
                    Curso de Aeronaves Agrícolas y Comerciales:
                      <p className="font-normal ">
                      Enfocado en el mantenimiento y operación segura de aeronaves utilizadas en el sector agrícola y de aviación comercial.
                      </p>
                    </li>
                    <li>
                    Curso de SMS (Sistema de Gestión de Seguridad Operacional):
                      <p className="font-normal ">
                      Capacitación en la implementación y gestión de un SMS efectivo para fomentar una cultura de seguridad operacional en organizaciones aeronáuticas.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Soporte */}
        <div>
          {/* titulo  */}
          <h3 ref={soporteRef} className="max-w-md py-4 pr-8 text-xl font-bold text-left text-white rounded-r-full bg-gradient-to-r from-lightblue to-lightblue2 pl-14 md:text-2xl lg:text-3xl">
            3. Soporte
          </h3>

          <div className="space-y-8 p-14">
            {/* Consultoría y asesoría */}
            <div className="flex flex-col items-center space-y-8 md:space-y-0 md:space-x-12 md:flex-row">
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-lg font-semibold border-b-4 w-fit md:text-lg lg:text-xl text-darkblue border-b-lightblue">
                  Consultoría y Asesoría
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  <ul className="pt-4 space-y-4 font-medium list-disc list-inside">
                    <li>
                    Auditorías en Áreas Técnicas, Legales y Financieras:
                      <p className="font-normal ">
                      Realizamos auditorías detalladas para identificar oportunidades de mejora y asegurar que las operaciones cumplan con normativas, minimizando riesgos y optimizando la eficiencia.
                      </p>
                    </li>
                    <li>
                    Consultoría en Regulaciones y Legislación Aeronáutica:
                      <p className="font-normal ">
                      Brindamos orientación especializada en el cumplimiento de normativas aeronáuticas locales e internacionales, garantizando que las operaciones cumplan con todos los requisitos legales.
                      </p>
                    </li>
                    <li>
                    Asesoría Técnica, Legal y Financiera:
                      <p className="font-normal ">
                      Ofrecemos soporte en decisiones clave a través de asesorías en temas técnicos, legales y financieros, adaptadas a las necesidades específicas de cada cliente para optimizar su operación.
                      </p>
                    </li>
                    <li>
                    Aseguramiento y Control de Calidad:
                      <p className="font-normal ">
                      Implementamos programas de aseguramiento de calidad para verificar que los procesos se alineen con los estándares de la industria, promoviendo operaciones seguras y eficientes.
                      </p>
                    </li>
                    <li>
                    Asesoría a Operadores ante Direcciones Generales de Aviación:
                      <p className="font-normal ">
                      Apoyamos a operadores en sus gestiones y trámites ante las Direcciones Generales de Aviación, facilitando la obtención de autorizaciones y el cumplimiento regulatorio.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover rounded-xl"
                  src={S8}
                  alt="Capacitaciones"
                />
              </div>
            </div>
            {/* Soporte Técnico */}
            <div className="flex flex-col-reverse items-center md:space-y-0 md:space-x-12 md:flex-row">
                {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover mt-8 md:mt-0 rounded-xl"
                  src={S9}
                  alt="Capacitaciones"
                />
              </div>
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-lg font-semibold border-b-4 w-fit md:text-lg lg:text-xl text-darkblue border-b-lightblue">
                Soporte Técnico
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  <ul className="pt-4 space-y-4 font-medium list-disc list-inside">
                    <li>
                    Análisis de Accidentes e Incidentes:
                      <p className="font-normal ">
                      Realizamos investigaciones exhaustivas de accidentes e incidentes aeronáuticos, tanto para entidades estatales como privadas, con el fin de determinar causas y proporcionar recomendaciones para mejorar la seguridad operacional.
                      </p>
                    </li>
                    <li>
                    Soporte Técnico, Administrativo y Legal:
                      <p className="font-normal ">
                      Proporcionamos asistencia integral en aspectos técnicos, administrativos y legales para satisfacer las necesidades de nuestros clientes, asegurando una operación conforme a las normativas vigentes.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
              
            </div>
            {/* Servicios en accidentes */}
            <div className="flex flex-col items-center space-y-8 md:space-y-0 md:space-x-12 md:flex-row">
              {/* texto */}
              <div className="space-y-4 md:w-1/2">
                <h4 className="pb-2 pr-8 text-lg font-semibold border-b-4 w-fit md:text-lg lg:text-xl text-darkblue border-b-lightblue">
                Servicios en accidentes e incidentes aeronáuticos
                </h4>
                <p className="text-sm font-normal text-justify text-darkblue md:text-base lg:text-lg">
                  <ul className="pt-4 space-y-4 font-medium list-inside">
                    <li>
                    Animación Digital:
                      <p className="font-normal ">
                      Utilizamos animaciones digitales para representar visualmente los eventos aeronáuticos, lo que permite una mejor comprensión de las dinámicas involucradas y facilita la identificación de factores que contribuyen a incidentes.
                      </p>
                    </li>
                    <li>
                    Asesorías en Avalúos y Peritajes Judiciales Aeronáuticos:
                      <p className="font-normal ">
                      Proporcionamos asesoría especializada en la evaluación de aeronaves y en la realización de peritajes judiciales, asegurando que nuestros clientes reciban informes precisos y confiables para sus necesidades legales y administrativas.
                      </p>
                    </li>
                    <li>
                    Consultorías y Servicios de Planificación Técnica (Planning):
                      <p className="font-normal ">
                      Brindamos consultorías en planificación técnica, ayudando a las empresas a optimizar sus operaciones y recursos, asegurando que se cumplan los objetivos de eficiencia y seguridad.
                      </p>
                    </li>
                    <li>
                    Dirección de Mantenimiento y Aseguramiento de Control de Calidad:
                      <p className="font-normal ">
                      Nos encargamos de la dirección de mantenimiento, implementando prácticas de aseguramiento de calidad que cumplen con los estándares de la industria, además de proporcionar capacitación y certificación al personal técnico involucrado.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
              {/* imagen  */}
              <div className="flex items-center justify-center md:w-1/2">
                <img
                  className="object-cover rounded-xl"
                  src={S10}
                  alt="Capacitaciones"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Call to action */}
      <div className="items-center justify-center w-full text-center bg-white py-14">
      <h2 className="text-xl font-normal md:text-3xl lg:text-5xl text-darkblue">¿Te interesó alguno de nuestros Servicios?</h2>
      <h2 className="text-xl font-bold md:text-3xl lg:text-5xl text-darkblue">¡Escríbenos!</h2>
      </div>

      <Contact />
      <Footer />
    </motion.div>
  );
};

export default Servicios;
