import React from "react";
import whitelogo from "../../assets/images/whitelogo.svg";
import { Facebook, Instagram, Linkedin } from "lucide-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFacebook, faSquareInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  const currentYear = new Date().getFullYear(); // Obtener el año actual dinámicamente

  return (
    <footer class="bg-darkgray shadow">
      <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center md:mx-8 sm:justify-between">
          <a
            href="/"
            class="flex justify-center items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img
              src={whitelogo}
              class="h-8 md:h-12 lg:h-14"
              alt="Seproa Logo"
            />
          </a>

{/* Links inner page  */}
          <ul class="flex justify-center flex-wrap items-center mb-6 text-sm font-medium text-gray-200 sm:mb-0">
            <li>
              <a href="/" class="hover:border-b py-1 border-white me-4 md:me-6">
                Inicio
              </a>
            </li>
            <li>
              <a href="/nosotros" class="hover:border-b py-1 border-white me-4 md:me-6">
                Nosotros
              </a>
            </li>
            <li>
              <a href="/servicios" class="hover:border-b py-1 border-white me-4 md:me-6">
                Servicios
              </a>
            </li>
            <li>
              <a href="/contacto" class="hover:border-b py-1 border-white e-4m md:me-6">
                Contacto
              </a>
            </li>
          </ul>

          {/* desarrolado por  */}
        <div class="flex flex-col items-center justify-center text-sm text-gray-300 dark:text-gray-400">
          <div class=" pb-2">Sitio Web desarrollado por:</div>
          {/* Boton linkedIn  */}
          {/* <div> */}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/andres-melendez-carvajal/"
            class="flex border-2 border-white p-2 hover:scale-110 duration-700 rounded-lg items-center space-x-2 font-semibold hover:bg-white hover:text-darkgray "
          >
            <Linkedin class="" />
            <span>Andrés Meléndez</span>
          </a>
          {/* </div> */}
        </div>

        
        </div>
        <hr class="my-6 border border-gray-200 sm:mx-auto lg:my-8" />
        <div className="flex flex-col-reverse items-center justify-between mx-8 md:flex-row">
            
          {/* Copyright  */}
          <div>
            <span class="block text-center w-full py-4 text-sm text-gray-300 sm:text-center dark:text-gray-400">
              &copy; {currentYear} SEPROA S.A. Todos los derechos reservados.
            </span>
          </div>

          {/* Redes sociales  */}
          <div class="flex bg-wite pr-6 flex-row items-center justify-between">
            <div class="flex mt-4 sm:justify-center text-white md:mt-0 space-x-5 rtl:space-x-reverse">
            <FontAwesomeIcon icon={faSquareFacebook} className="text-3xl text-white duration-300 hover:scale-110" />
      <FontAwesomeIcon icon={faSquareInstagram} className="text-3xl text-white duration-300 hover:scale-110" />
      <FontAwesomeIcon icon={faLinkedin} className="text-3xl text-white duration-300 hover:scale-110" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
